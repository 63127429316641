<template>
  <div class="fund-raiser">
    <div class="fund-raiser__header">
      <i class="icon icon-star_badge"></i>
      <h2 class="fund-raiser__title">Fundraiser Articles</h2>
    </div>
    <div class="articles">
      <a-row :gutter="[18, 18]">
        <a-col
          :xs="24"
          :sm="12"
          :md="8"
          :lg="6"
          v-for="article in articles"
          :key="article.id"
        >
          <article-card
            :title="article.title"
            :author-name="article.authorName"
            :image="article.image"
            :profile-photo="article.profilePhoto"
          ></article-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import ArticleCard from "./FundraiserArticleCard.vue";

export default {
  components: {
    ArticleCard,
  },
  data() {
    return {
      articles: [
        {
          id: 1,
          title: "Tesla is organized into two main divisions based on their different product categories. Therefore there are two segments of infrastructure too.",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/car.jpg"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
        },
        {
          id: 2,
          title: "Elon Musk Chief Executive Officer of Tesla Motors",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/Elon-Musk.jpg"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
        },
        
        {
          id: 3,
          title: "Olympics 2021 - SOP's Being strictly followed",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image1@2x.png"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
        },
        {
          id: 4,
          title: "Tesla surpassed a market value of $1 trillion on Monday, making it the fifth such firm to reach the milestone.",
          authorName: "Zaynab Farz",
          image: require("../../assets/images/card-image2.png"),
          profilePhoto: require("../../assets/images/profile-pic.webp"),
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.fund-raiser {
  @include reset-css;
  margin: 0 2rem;
  padding: 3rem 5rem;
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.93);

  .articles {
    position: relative;
    z-index: 20000;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 4rem;
    
  }
  &__title {
    font-size: 3.6rem;
    font-family: $font-secondary-bold;
    font-weight: 900;
    @include respond(phone-large) {
      font-size: 2rem;
    }
  }
  .icon {
    font-size: 3rem;
    margin-right: 1rem;
  }
}
</style>
